<template>
  <div>
    <div v-if="compareOrder.length > 0">
      <v-window v-model="step">
        <v-window-item :value="1">
          <br>
          <div>
            <v-col class="text-right">
              <v-btn text color="grey lighten-0.3" @click="clearHistory()" >
                Clear History
              </v-btn>
            </v-col>
          </div>
          <v-card
            v-for="(v, i) in this.compareOrder.reverse()"
            :key="i"
            class="rounded-lg mx-auto mb-4"
            color="white"
            elevation="5"
            max-width="1150"
          >
            <v-list-item three-line @click="findOrder(v)">
              <v-list-item-content>
                <div class="text-overline">
                  <!-- {{v.datetime}} -->
                  {{ dayjs(v.datetime).format("DD MMM YYYY - hh:mm A") }}
                </div>
                <v-list-item-title class="text-h5 mb-5">
                  {{v.cart[0].name}}
                  <v-list-item-title class="text-subtitle sm-3">
                  Total: RM {{currency(v.cart[0].unit_price)}} x {{v.cart[0].quantity}}
                </v-list-item-title>
                <v-list-item-title class="text-subtitle sm-3">
                  <div v-if="v.cart.length > 1">
                        and {{(v.cart.length -1)}} more items
                      </div>
                </v-list-item-title>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-avatar tile size="80" color="grey" class="mt-16">
                <v-img
                  v-if="v.cart[0].media_urls.length > 0"
                  max-height="120"
                  max-width="140"
                  :src="image(v.cart[0].media_urls[0])"
                ></v-img>
                <v-img
                  v-else
                  class="rounded-l-lg"
                  max-height="120"
                  max-width="140"
                  :src="emptyImage()"
                ></v-img>
              </v-list-item-avatar>
              
            </v-list-item>
            <v-card-actions class="leaf" @click="buyAgain(v)">
                <span class="white--text text-caption text-uppercase mx-auto">order again</span>
            </v-card-actions>
          </v-card>

        
        </v-window-item>
        <v-window-item :value="2">
          <div>
            <div class="" v-if="status">
              <v-list-item-subtitle class="d-flex justify-center">
                {{
                  dayjs(status.datetime).format("DD MMM YYYY - hh:mm A")
                }}</v-list-item-subtitle
              >

              <div>
                <v-container grid-list-xs class="px-0 pb-0 mt-0">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-card
                        color="white"
                        class="rounded-t-xl pa-3 pt-0"
                        elevation="0"
                        min-height="90vh"
                      >
                        <!-- <div v-if="alertTop.length !== 0">
              <v-alert
                type="error"
                :value="true"
                color="red"
                v-for="(value, i) in alertTop"
                :key="i"
              >
                {{ value }}
              </v-alert>
            </div> -->
                        <div>
                          <v-card color="white" class="rounded-lg pa-3" flat>
                            <v-row>
                              <v-col cols="10" lg="11">
                                <div
                                  class="flex flex-column black--text source ml-3"
                                  style="line-height: 1"
                                >
                                  <h4>{{ selectedUser.name || "" }}</h4>
                                  <h4>{{ selectedUser.address || "" }}</h4>
                                  <h4>{{ selectedUser.address2 || "" }}</h4>
                                  <h4>{{ selectedUser.postcode || "" }}</h4>
                                  <h4>{{ selectedUser.phone || "" }}</h4>
                                  <h4>{{ selectedUser.email || "" }}</h4>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card>
                          <v-divider class="my-4"></v-divider>
                          <v-row v-for="(v, i) in status.cart" :key="i" >
                            <v-col cols="12" class="py-0">
                              <v-card
                                color="white"
                                class="rounded-lg mt-3 pa-3"
                              >
                                <v-row>
                                  <v-col cols="4" lg="2" class="my-auto">
                                    <v-img
                                      v-if="v.media_urls.length > 0"
                                      class="rounded-l-lg"
                                      max-height="120"
                                      max-width="140"
                                      :src="image(v.media_urls[0])"
                                    ></v-img>
                                    <v-img
                                      v-else
                                      class="rounded-l-lg"
                                      max-height="120"
                                      max-width="140"
                                      :src="emptyImage()"
                                    ></v-img>
                                    <!-- <v-sheet v-else>
                          <span class="text-center grey lighten-3 rounded-lg"
                            ><h3 class="py-2 blue-grey--text text-lighten-3">
                              placeholder
                            </h3></span
                          >
                        </v-sheet> -->
                                  </v-col>
                                  <v-col cols="6" lg="9">
                                    <div
                                      class="flex flex-column justify-space-between h"
                                    >
                                      <div
                                        class="flex flex-column source grey--text"
                                        style="line-height: 1.2"
                                      >
                                        <span
                                          class="flex flex-column black--text"
                                        >
                                          <h5>{{ v.tags[0].name }}</h5>
                                          <h3>{{ v.name }}</h3>
                                          <div
                                            v-if="v.modifiers.length > 0"
                                            class=""
                                          >
                                            <span
                                              class="flex"
                                              v-for="(
                                                val, ind
                                              ) in v.modifiers"
                                              :key="ind"
                                            >
                                              <h5>{{ showMod(val) }}</h5>
                                            </span>
                                          </div>
                                          <!-- {{v.variant}} -->
                                          <div
                                            v-if="
                                              v.variant[0] &&
                                              v.price_type == 0
                                            "
                                            class=""
                                          >
                                            <span
                                              class="flex"
                                              v-for="(
                                                values, indexs
                                              ) in v.variant"
                                              :key="indexs"
                                            />
                                          </div>
                                          <div
                                            v-if="
                                              v.variant[0] &&
                                              v.price_type == 0
                                            "
                                            class=""
                                          >
                                            <span
                                              class="flex"
                                              v-for="(
                                                values, indexs
                                              ) in v.variant"
                                              :key="indexs"
                                            >
                                              <h5>{{ showAmt(values) }}</h5>
                                            </span>
                                          </div>
                                          <h5
                                            class="font-weight-bold"
                                            v-if="v.remarks"
                                          >
                                            Remarks:
                                          </h5>
                                          <h5
                                            class="font-weight-bold blue-grey--text"
                                            v-if="v.remarks"
                                          >
                                            {{ v.remarks }}
                                          </h5>
                                        </span>
                                      </div>
                                      <div
                                        class="flex black--text flex-column justify-end"
                                      >
                                        <h3 class="mt-1">
                                          RM
                                          {{
                                            v.price_type != 0
                                              ? currency(v.unit_price)
                                              : currency(v.variant[0].price)
                                          }}
                                        </h3>
                                      
                                      </div>
                                    </div>
                                  </v-col>
                                  <v-col
                                    cols="2"
                                    lg="1"
                                    class="flex flex-column justify-space-between"
                                  >
                                    <v-btn
                                      color="gold"
                                      x-small
                                      fab
                                      elevation="0"
                                      ><h3
                                        class="font-weight-bold text-lowercase"
                                      >
                                        x {{ v.quantity }}
                                      </h3></v-btn
                                    >
                                  
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                          <!-- summary -->
                          <v-row>
                            <v-col cols="12" class="pb-0">
                              <v-card class="rounded-lg" elevation="0">
                                <div class="pa-3 source black--text">
                                  <h2>Summary</h2>
                                  <v-divider></v-divider><br>
                                  <!-- <v-divider class="my-2" v-if="total"></v-divider> -->
                                  <!-- <div v-if="total">
                        <div class="flex justify-space-between px-3">
                          <h4 v-if="inclusive.status">
                            Subtotal (Include SST tax)
                          </h4>
                          <h4 v-else>Subtotal</h4>
                          <h4 class="lato">
                            {{ currency(total) }}
                          </h4>
                        </div>
                      </div> -->
                                  <div
                                    class="flex justify-space-between px-3"
                                    v-if="voucher"
                                  >
                                    <h4>
                                      Voucher
                                      {{
                                        voucher.delivery
                                          ? "(Delivery)"
                                          : "(Item)"
                                      }}
                                    </h4>
                                    <h4 class="lato" v-if="voucher.deduct">
                                      - {{ currency(voucher.deduct) }}
                                    </h4>
                                    <h4 class="lato" v-if="voucher.discount">
                                      - {{ voucher.discount }}%
                                    </h4>
                                    <!-- <h4
                          class="lato"
                          v-if="selectedDelivery != null && voucher.delivery"
                        >
                          -
                          {{
                            currency(
                              selectedDelivery.data.delivery_fee -
                                voucher.delivery
                            ) > 0
                              ? currency(voucher.delivery)
                              : currency(selectedDelivery.data.delivery_fee)
                          }}
                        </h4> -->
                                  </div>
                                  <!-- <div v-if="inclusive.status == false">
                        <div
                          class="flex justify-space-between px-3"
                          v-if="currency(taxed).value !== 0"
                        >
                          <h4>SST Tax ({{ parseInt(taxed) }}%)</h4>
                          <h4 class="lato">
                            +
                            {{ currency(total).multiply(taxed).divide(100) }}
                          </h4>
                        </div>
                      </div> -->
                                  <!-- <div class="flex px-3" v-if="selectedDelivery != null">
                        <h4>Delivery :</h4>
                        <div
                          class="flex justify-space-between"
                          v-if="
                            selectedDelivery.provider &&
                            selectedDelivery.provider !== ''
                          "
                        >
                          <h4
                            class="lato text-capitalize ml-2"
                            v-if="selectedDelivery"
                          >
                            {{ selectedDelivery.name }}
                          </h4>
                          <h4 class="lato">
                            +
                            {{ currency(selectedDelivery.data.delivery_fee) }}
                          </h4>
                        </div>
                        <div v-else>
                          <h4
                            class="lato text-capitalize ml-2"
                            v-if="selectedDelivery"
                          >
                            {{ selectedDelivery.name }}
                          </h4>
                        </div>
                      </div> -->
                                  <div
                                    class="flex px-3"
                                    v-if="status.delivery != false"
                                  >
                                    <h4>Delivery :</h4>
                                    {{ status.delivery_detail.service }}
                                  </div>
                                  <div class="flex px-3" v-else>
                                    <h4>Delivery :</h4>
                                    Self Pickup
                                  </div>

                                  <div
                                    v-if="franchise.packaging_fees"
                                    class="flex justify-space-between px-3"
                                  >
                                    <h4>Packaging Fees</h4>
                                    <h4 class="lato">
                                      +
                                      {{ currency(franchise.packaging_fees) }}
                                    </h4>
                                  </div>
                                  <div class="flex justify-space-between">
                                    <h2>Total</h2>
                                    <h2 class="lato">
                                      RM
                                      {{currency(status.totalOrder) }}
                                    </h2>
                                  </div>
                                </div>
                              </v-card>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12">
                              <div></div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
          </div>
          <!-- end of history detail -->
        </v-window-item>
      </v-window>
    </div>
    <div v-else>
      <v-list-item-subtitle class="d-flex justify-center">
        There is no order history
      </v-list-item-subtitle>
    </div>
    <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      persistent
      overlay-color="white"
      overlay-opacity="0.9"
      max-width="500px"
    >
      <v-card>
        <div v-if="outOfStock">
          <v-card-title class="text-h5 red--text">
            There is item that out of stock or sold out
          </v-card-title>
          <v-card-text class="black--text">
            Please choose other or manually buy again
          </v-card-text>
        </div>
        <div v-else>
          <v-card-title class="text-h5">
            Are you sure?
          </v-card-title>
          <v-card-text class="black--text">
            Proceeding will remove any item from the cart.
          </v-card-text>
        </div>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="leaf"
            text
            @click="dialog = false, outOfStock = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="leaf"
            text
            @click="addToCart"
            :disabled="outOfStock"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </div>
  
</template>
<!-- <template>
  <div>
    <h1>List of history</h1>
    <h3>{{selectedUser.name}}</h3>
    <h3>{{franchise}}</h3>
    <h3>{{displayOrder}}</h3>
    <h3>{{compareOrder}}</h3>
  </div>
</template> -->

<script>
import { mapGetters } from "vuex";

export default {
  name: "history",
  data() {
    return {
      status: null,
      step: 1,
      products: [],
      dialog: false,
      outOfStock: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "getUsers",
      order: "getOrder",
      franchise: "getFranchise",
      voucher: "getVoucher",
      selectedHistory: "getSelectedHistory",
      product: "getProduct"
    }),
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
    unselectedUser() {
      return this.user.filter((d) => d.selected == false);
    },
    compareOrder() {
      return this.order.filter(
        (d) => d.customer.name == this.selectedUser.name
      );
    },
    dayjs() {
      return require("dayjs");
    },
    currency() {
      return require("currency.js");
    },
    
  },
  methods: {
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },
    clearHistory() {
      localStorage.removeItem("order");
      setTimeout("location.reload(true);", 500);
    },
    findOrder(value) {
      // let a = this.order.findIndex((d)=> d.cart.name = this.compareOrder.name);
      this.status = value;
      this.step++;
      // console.log("this is status of this order :", this.status);
    },
    buyAgain(v) {
        this.products = v.cart
        this.dialog = true
        this.checkOutOfStock()
      },
      checkOutOfStock() {
        this.products.map((pastOrder) => {this.compareProduct(pastOrder)})
      },
      compareProduct(v) {
        this.product.map((current) => {
          if(v.id == current.id){
            this.setOutOfStock(current)
          }
        })
      },
      setOutOfStock(v) {
        if(v.total_left == 0 || v.out_of_stock){
          this.outOfStock = true
          console.log('out of stock', this.outOfStock)
        }
      },
      addToCart() {
          this.$store.dispatch("updateCart", []);
          for(let product of this.products){
            this.$store.commit("addToCart", product);
          }
          this.dialog = false
      },
  },
  beforeMount() {
    if(this.selectedHistory){
      this.status = this.selectedHistory;
      this.step++;
    }
  },
  beforeDestroy() {
    this.status = null;
    this.step--;
    this.$store.dispatch("removeSH");
  }
};
</script>
